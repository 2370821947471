.App{
  height: 100%;
}
.tab-container {
  height: 100%;
}
.ant-tabs-nav-wrap{
  padding: 0 20px;
}
.ant-tabs-content{
  height: 100%;
}